import React, {useState, useContext} from 'react';
import axios from 'axios';
import {AuthContext} from './AuthContext';
import './loginpage.css';

interface LoginResponse {
    token: string;
}

const LoginPage: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const authContext = useContext(AuthContext);

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            console.log("Agora vai tentar pegar a response:", username, password);
            const response = await axios.post<LoginResponse>('https://api.bc.turismourbano.com.br/api/auth/login', {
                username,
                password,
            });

            console.log("response:", response.data.token);
            authContext?.login(response.data.token);
            window.location.href = '/dashboard';
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                setError('Credenciais inválidas ou erro na conexão.');
            } else {
                setError('Erro desconhecido.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <h2>Painel Administrativo</h2>
                <form onSubmit={handleLogin}>
                    <div className="login-form-group">
                        <label htmlFor="username">Usuário</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="login-form-group">
                        <label htmlFor="password">Senha</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button className="login-button" type="submit" disabled={isLoading}>
                        {isLoading ? 'Carregando...' : 'Entrar'}
                        <svg fill="white" viewBox="0 0 448 512" height="1em" className="arrow">
                            <path
                                d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5
                                32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4
                                393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                        </svg>
                    </button>
                    {error && <p className="error-message">{error}</p>}
                </form>
            </div>
        </div>
    );
};
export default LoginPage;
