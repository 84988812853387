import React, {useContext, useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {AuthContext} from './AuthContext';

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({children}) => {
    const authContext = useContext(AuthContext);
    const [isChecking, setIsChecking] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            authContext?.login(token);
        }

        setIsChecking(false);
    }, []);


    if (isChecking) {
        return <div>Loading...</div>
    }

    if (!authContext?.isAuthenticated) {
        return <Navigate to="/login"/>;
    }

    return children;
};


export default ProtectedRoute;
