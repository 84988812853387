import React, {useContext, useState, useEffect} from 'react';
import {AuthContext} from "./AuthContext";
import {useNavigate} from 'react-router-dom';
import './dashboard.css';
import MapComponent from "./MapComponent";
import PlaceComponent from "./PlaceComponent";
import AdComponent from "./AdComponent";
import AdEditComponent from "./AdEditComponent";
import {Link} from "react-router-dom";

const Dashboard: React.FC = () => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const getInitialState = (key: string, defaultValue: boolean) => {
        const savedState = localStorage.getItem(key);
        return savedState !== null ? JSON.parse(savedState) : defaultValue;
    };

    const [showMap, setShowMap] = useState(getInitialState('showMap', true));
    const [showAdEdit, setShowAdEdit] = useState(getInitialState('showAdEdit', false));

    useEffect(() => {
        localStorage.setItem('showMap', JSON.stringify(showMap));
        localStorage.setItem('showAdEdit', JSON.stringify(showAdEdit));
    }, [showMap, showAdEdit]);

    const handleLogout = () => {
        authContext?.logout();
        navigate('/login');
    };

    const toggleComponent = () => {
        setShowMap(!showMap);
        setShowAdEdit(!showAdEdit);
    };

    return (
        <div className="dash-container">
            <header className="dash-header">
                <div className="dash-home-header">
                    <button className="dash-home-button">
                        <Link to="/" style={{textDecoration: "none", color: "inherit"}}>
                            Voltar à Página Inicial
                        </Link>
                    </button>
                </div>
                <h1>Painel Administrativo</h1>
                <div className="dash-switch-header">
                    <button className='dash-switch-button' onClick={toggleComponent}>
                        {showMap ? "Cadastrar Anúncios" : "Cadastrar Locais"}
                    </button>
                </div>
                <div className="dash-logout-header">
                    <button className="dash-logout-button" onClick={handleLogout}>Sair</button>
                </div>
            </header>
            <main className="dash-main">
                <section className="dash-section">
                    {showMap ? <MapComponent/> : <AdComponent/>}
                </section>
                <section className="dash-section">
                    {showAdEdit ? <AdEditComponent/> : <PlaceComponent/>}
                </section>
            </main>
        </div>
    );
}

export default Dashboard;
