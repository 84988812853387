import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './style.css';
import {Link} from "react-router-dom";
import LogoPontosTuristicos from '../../../../img/logos/PontosTuristicos.png';
import LogoBarRestaurante from '../../../../img/logos/LogoBaresRestaurantes.png';
import LogoHospedagem from '../../../../img/logos/LogoHospedagem.png';
import LogoCompras from '../../../../img/logos/LogoCompras.png';
import LogoLazer from '../../../../img/logos/LogoLazer.png';
import LogoTurismo from '../../../../img/logos/logoTurismoUrbanobranco.png';

const HeaderApp = () => {
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    return (
        <div className="header-body">
            <nav className="navbar navbar-expand-lg bg-blue">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand">
                        <img src={LogoTurismo} className="logo" alt="Logo Turismo"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <Link to='/PontosTuristicos' className="nav-link">
                                <img src={LogoPontosTuristicos} className="nav-logo" alt="Pontos Turísticos"/>
                                Pontos Turísticos
                            </Link>
                            <Link to='/Restaurante' className="nav-link">
                                <img src={LogoBarRestaurante} className="nav-logo" alt="Bares/Restaurantes"/>
                                Bares/Restaurantes
                            </Link>
                            <Link to='/Hospedagem' className="nav-link">
                                <img src={LogoHospedagem} className="nav-logo" alt="Hospedagem"/>
                                Hospedagem
                            </Link>
                            <Link to='/Lojas' className="nav-link">
                                <img src={LogoCompras} className="nav-logo" alt="Lojas"/>
                                Lojas
                            </Link>
                            <Link to='/Lazer' className="nav-link">
                                <img src={LogoLazer} className="nav-logo" alt="Lazer"/>
                                Lazer
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default HeaderApp;
