import React, {useEffect, useState} from 'react';
import Card from './Card';
import {getPlaces} from '../../../../api/api';

export interface Local {
    id: number;
    name: string;
    imageUrls: [];
    description: string;
    latitude: number;
    longitude: number;
    categoryId: number;
    value: number;
    placeId: string;
}

interface CardListCheckedProps {
    categoryId: number;
}

const CardListChecked: React.FC<CardListCheckedProps> = ({categoryId}) => {
    const [cards, setCards] = useState<Local[]>([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        loadInitialCards();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [categoryId, searchTerm]);

    const loadInitialCards = async () => {
        setLoading(true);
        try {
            const allCards = await getPlaces();
            const filteredCards = allCards
                .filter((card: Local) => card.categoryId === categoryId && card.name.toLowerCase().includes(searchTerm.toLowerCase())) // Filtra pelo termo de pesquisa
                .sort((a: { value: number; }, b: {
                    value: number;
                }) => b.value - a.value)
                .slice(0, 3);
            setCards(filteredCards);
        } catch (error) {
            console.error('Erro ao carregar os locais:', error);
        } finally {
            setLoading(false);
        }
    };

    const loadMoreCards = async () => {
        setLoading(true);
        try {
            const allCards = await getPlaces();
            const filteredCards = allCards
                .filter((card: Local) => card.categoryId === categoryId && card.name.toLowerCase().includes(searchTerm.toLowerCase())) // Filtra pelo termo de pesquisa
                .sort((a: { value: number; }, b: {
                    value: number;
                }) => b.value - a.value)
                .slice(0, 3);
            setCards(filteredCards);
        } catch (error) {
            console.error('Erro ao carregar mais locais:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200
            && !loading
        ) {
            loadMoreCards();
        }
    };

    return (
        <div>
            <div className="cards-principal">
                {cards.map((local) => (
                    <React.Fragment key={local.id}>
                        <Card local={local}/>
                    </React.Fragment>
                ))}
                {loading && (
                    <div className="text-center">
                        <p>Carregando mais locais...</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CardListChecked;
