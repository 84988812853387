import axios from 'axios';

const api = axios.create({
    baseURL: 'https://api.bc.turismourbano.com.br/api',
});

export const getCategories = async () => {
    const response = await api.get('/category');
    return response.data;
};

export const getCategory = async (id: number) => {
    const response = await api.get(`/category/${id}`);
    return response.data;
};

export const createCategory = async (category: { name: string }) => {
    const response = await api.post('/category', category);
    return response.data;
};

export const updateCategory = async (id: number, category: { id: number; name: string }) => {
    const response = await api.put(`/category/${id}`, category);
    return response.data;
};

export const deleteCategory = async (id: number) => {
    await api.delete(`/category/${id}`);
};


export const getPlaces = async () => {
    try {
        const response = await api.get('/places');
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar os lugares:', error);
        return [];
    }
};

export const createPlace = async (place: {
    latitude: number;
    imageUrls: string[];
    name: string;
    placeId: string;
    description: string;
    categoryId: number;
    longitude: number;
    value: number;
}) => {
    const response = await api.post('/places', place);
    return response.data;
};

export const updatePlace = async (id: number, category: { id: number; name: string }) => {
    const response = await api.put(`/places/${id}`, category);
    return response.data;
};

export const deletePlace = async (id: number) => {
    const response = await api.delete(`/places/${id}`);
    return response.data;
};

export const createAd = async (adData: {
    name: string;
    description: string;
    url: string;
    imageUrls: string[];
}) => {
    const response = await api.post('/ads', adData); // Use a instância `api`
    return response.data;
};

export const getAds = async () => {
    try {
        const response = await api.get('/ads');
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar os lugares:', error);
        return [];
    }
};
export const updateAd = async (id: number, category: { id: number; name: string }) => {
    const response = await api.put(`/ads/${id}`, category);
    return response.data;
};

export const deleteAd = async (id: number) => {
    const response = await api.delete(`/ads/${id}`);
    return response.data;
};

export const getPlace = async (id: number) => {
    const response = await api.get(`/places/${id}`);
    return response.data;
};
