import React, {useEffect, useState} from "react";
import LogoLazer from '../../../../../img/logos/LogoLazer.png';
import {Link} from "react-router-dom";
import CardListChecked from "../../Card/CardListChecked";

interface Lazer {
    id: number;
    nome: string;
    imagem: string;
    descricao: string;
}

const Lazer = () => {

    const desiredCategoryId = 5;
    return (
        <div className="background-container-fluid background-home-cards-fundo">
            <div className="d-flex align-items-center justify-content-center">
                <Link to="/Lazer" className="link-decoration">
                    <img src={LogoLazer} alt="Logo e Botão Que Leva Para A Página De Lazer" className="logos"/>
                    <h3 className="titulo ms-3 mb-0 text-black">Lazer</h3>
                </Link>
            </div>
            <div>
                <CardListChecked categoryId={desiredCategoryId}/>
            </div>
        </div>
    );
}
export default Lazer;
