import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import Home from "./Modules/Website/components/Home/Home";
import HospedagemApp from "./Modules/Website/components/Pages/Hospedagem";
import LazerApp from './Modules/Website/components/Pages/Lazer';
import RestauranteApp from './Modules/Website/components/Pages/BaresRestaurantes';
import LojaApp from "./Modules/Website/components/Pages/Loja";
import PontoApp from "./Modules/Website/components/Pages/PontoTuristico";
import LoginPage from "./components/LoginPage";
import Dashboard from "./components/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import {AuthProvider} from "./components/AuthContext";
import PlaceDetails from './Modules/Website/components/Tests/QrCode';


function AppRoutes() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/Lazer" element={<LazerApp/>}/>
                    <Route path="/Hospedagem" element={<HospedagemApp/>}/>
                    <Route path="/Restaurante" element={<RestauranteApp/>}/>
                    <Route path="/Lojas" element={<LojaApp/>}/>
                    <Route path="/PontosTuristicos" element={<PontoApp/>}/>
                    <Route path="/Login" element={<LoginPage/>}/>
                    <Route path="/local/:id" element={<PlaceDetails/>}/>

                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <Dashboard/>
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default AppRoutes;
