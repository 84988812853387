import React from "react";
import LogoBaresRestaurantes from '../../../../../img/logos/LogoBaresRestaurantes.png';
import {Link} from "react-router-dom";
import CardListChecked from "../../Card/CardListChecked";

interface Bares {
    id: number;
    nome: string;
    imagem: string;
    descricao: string;
}

const BaresRestaurantes = () => {
    const desiredCategoryId = 2;

    return (
        <div className="background-container-fluid background-home-cards-fundo">
            <div className="d-flex align-items-center justify-content-center">
                <Link to="/Restaurante" className="link-decoration">
                    <img src={LogoBaresRestaurantes} alt="Botão E Logo Que Leva Para Os Bares e Restaurantes"
                         className="logos"/>
                    <h3 className="titulo ms-3 mb-0 text-black">Bares / Restaurante</h3>
                </Link>
            </div>
            <div>
                <CardListChecked categoryId={desiredCategoryId}/>
            </div>
        </div>
    );
}

export default BaresRestaurantes;
