import React from "react";
import LogoImportantes from '../../../../../img/logos/LogoImportantes.png';
import Hospital from '../../../../../img/home/Hospital BC.jpg';
import Prefeitura from '../../../../../img/home/Prefeitura BC.jpg';
import Delegacia from '../../../../../img/home/Delegacia BC.jpg';

const Importantes = () => {
    return (
        <div className="background-container-fluid background-home-cards-fundo">
            <div className="d-flex align-items-center justify-content-center">
                <div className="link-decoration">
                    <img src={LogoImportantes} alt="Importantes" className="logos"/>
                    <h3 className="titulo ms-3 mb-0 text-black">Importantes</h3>
                </div>
            </div>
            <div className="cards-importantes">
                <div className="cards-importantes-col">
                    <div className="card h-100 border-0">
                        <img src={Hospital} className="card-img-top img-fluid"
                             alt="Imagem Ilustrativa Do Hospital De Balneário Camboriú"
                             style={{height: '200px', objectFit: 'cover'}}/>
                        <div className="card-body background-cards-home">
                            <h5 className="card-title fs-2 text-warning fw-bold">Hospital</h5>
                            <p className="card-text fs-6 text-light fw-medium">
                                Hospital Municipal Ruth Cardoso
                                <br/>
                                Telefone: (47) 3169-3700
                            </p>
                        </div>
                    </div>
                </div>
                <div className="cards-importantes-col">
                    <div className="card h-100 border-0">
                        <img src={Prefeitura} className="card-img-top img-fluid"
                             alt="Imagem Ilustrativa Da Prefeitura de Balneário Camboriú"
                             style={{height: '200px', objectFit: 'cover'}}/>
                        <div className="card-body background-cards-home">
                            <h5 className="card-title fs-2 text-warning fw-bold">Prefeitura</h5>
                            <p className="card-text fs-6 text-light fw-medium">
                                Prefeitura Municipal de Balneário Camboriú
                                <br/>
                                Telefone: (47) 3267-7000
                            </p>
                        </div>
                    </div>
                </div>
                <div className="cards-importantes-col">
                    <div className="card h-100 border-0">
                        <img src={Delegacia} className="card-img-top img-fluid"
                             alt="Imagem Ilustrativa Da Delegacia de Polícia Civil de Balneário Camboriú"
                             style={{height: '200px', objectFit: 'cover'}}/>
                        <div className="card-body background-cards-home">
                            <h5 className="card-title fs-2 text-warning fw-bold">Delegacia de Polícia</h5>
                            <p className="card-text fs-6 text-light fw-medium">
                                29ª Delegacia Regional de Polícia
                                <br/>
                                Telefone: (47) 3367-0399
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Importantes;
