import React from "react";
import CardListChecked from "../../Card/CardListChecked";
import LogoPontoTuristico from "../../../../../img/logos/PontosTuristicos.png";
import {Link} from "react-router-dom";

const desiredCategoryId = 1;

const PontosTuristicos = () => {
    return (
        <div className="background-container-fluid background-home-cards-fundo">
            <div className="d-flex align-items-center justify-content-center">
                <Link to="/PontosTuristicos" className="link-decoration">
                    <img src={LogoPontoTuristico} alt="Logo E Botão Que Leva Para A Página De Pontos Turísticos"
                         className="logos"/>
                    <h3 className="titulo ms-3 mb-0 text-black">Pontos Turisticos</h3>
                </Link>
            </div>
            <div>
                <CardListChecked categoryId={desiredCategoryId}/>
            </div>
        </div>
    );
}

export default PontosTuristicos;
