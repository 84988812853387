import React, {useState} from "react";
import LogoCuriosidades from '../../../../../img/logos/LogoCuriosidades.png';
import LocationBC from '../../../../../img/Location-map-of-Balneario-Camboriu-SC-Brazil.png';
import SCFlag from '../../../../../img/BandeiraSantaCatarina.png';
import BalnearioFlag from '../../../../../img/flags/Bandeira BC.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';

const Informacoes = () => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <div className="background-container-fluid background-home-cards-fundo">
            <div className="d-flex align-items-center justify-content-center">
                <div className="link-decoration">
                    <img src={LogoCuriosidades} alt="Logo Sobre As Curiosidades" className="logos"/>
                    <h3 className="titulo ms-3 mb-0 text-black">Informações</h3>
                </div>
            </div>
            <div className="card-info">
                <div className="card text-center" onClick={handleShow}>
                    <div className="card-header">
                    <img src={LocationBC} className="rounded img-fluid"
                        alt="Imagem Da Divisao de bairros Balneário Camboriú"
                        style={{ width: '90%', maxWidth: '800px', height: 'auto' }} />

                    </div>
                    <div className="card-body">
                        <h5 className="card-title fs-4 text-warning fw-bold">Informações da cidade</h5>
                        <p className="card-text fs-6 text-light fw-medium">Clique para saber mais</p>
                    </div>
                </div>
            </div>
            {show && (
                <div className="modal fade show d-block" tabIndex={-1} role="dialog"
                     aria-labelledby="exampleModalCenterTitle" aria-hidden={!show}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Informações de Balneário Camboriú</h5>
                                <button type="button" className="btn-close" onClick={handleClose}
                                        aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="info-flags row mb-3">
                                    <div className="col text-center">
                                        <img src={BalnearioFlag} width={200} height={150}
                                             alt="Imagem Da Bandeira de Balneário Camboriú"/>
                                        <h6>Bandeira de Balneário Camboriú</h6>
                                    </div>
                                    <div className="col text-center">
                                        <img src={SCFlag} alt="Imagem da Bandeira de Santa Catarina"/>
                                        <h6>Bandeira de Santa Catarina</h6>
                                    </div>
                                </div>
                                <h1 className="fw-bold fs-1 fst-italic text-center">BALNEÁRIO CAMBORIÚ</h1>
                                <div className="informacoes">
                                    <p className="fs-4"><strong>Região Turística</strong>: Costa Verde & Mar</p>
                                    <p className="fs-4"><strong>Área</strong>: 46,45 km²</p>
                                    <p className="fs-4"><strong>População</strong>: 145.796 habitantes (estimativa 2023)</p>
                                    <p className="fs-4"><strong>Índice de Desenvolvimento Municipal Sustentável</strong>:
                                        0,845.</p>
                                    <p className="fs-4"><strong>Latitude</strong>: 26°59'43" S </p>
                                    <p className="fs-4"><strong>Longitude</strong>: 48°38'01" O </p>
                                    <p className="fs-4"><strong>Altitude</strong>: 2 m</p>
                                    <p className="fs-4"><strong>Cidades próximas</strong>: Itajaí, Itapema , Navegantes,
                                    Brusque, Blumenau, Florianópolis.</p>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>Fechar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {show && (
                <div className="modal-backdrop fade show"></div>
            )}
        </div>
    );
}

export default Informacoes;
