import React from "react";
import NBWLogo from "../../../../../img/sponsors/nbwdigital.png";

const Footer = () => {
    return (
        <div style={{ position: 'relative' }}>
            <div className="patrocinio">
                PATROCINADORES
                <div className="patrocinio-logo">
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                    <img src={NBWLogo}/>
                </div>
            </div>
            <div className="bg-warning text-dark p-3" style={{  width: '100%' }}>
                <div className="row h-100 align-items-center">
                    <div className="col-md-4  winter-summer-text-color">
                        <p className="fw-medium mb-1 my-5">Location:</p><br /><p className="fs-3 fw-semibold">Balneário Camboriú</p>
                    </div>
                    <div className="col-md-4 winter-summer-text-color ">
                        <p className="fw-medium mb-1 my-5">E-Mail:</p><br /><p className="fs-3 fw-semibold">BC@email.com.br</p>
                    </div>
                    <div className="col-md-4 winter-summer-text-color">
                        <p className="fw-medium mb-1 my-5">Contact Number:</p><br /><p className="fs-3 fw-semibold">(01) 2345-6789</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
