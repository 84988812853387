import React, {useEffect, useState} from "react";
import LogoHospedagem from '../../../../../img/logos/LogoHospedagem.png';
import {Link} from "react-router-dom";
import CardListChecked from "../../Card/CardListChecked";

interface Hotel {
    id: number;
    nome: string;
    imagem: string;
    descricao: string;
}

const Hospedagem = () => {
    const desiredCategoryId = 3;
    return (
        <div className="background-container-fluid background-home-cards-fundo">
            <div className="d-flex align-items-center justify-content-center">
                <Link to="/Hospedagem" className="link-decoration">
                    <img src={LogoHospedagem} alt="Logo e Botão Para Levar Para A Página De Hospedagem"
                         className="logos"/>
                    <h3 className="titulo ms-3 mb-0 text-black">Hospedagem</h3>
                </Link>
            </div>
            <div>
                <CardListChecked categoryId={desiredCategoryId}/>
            </div>
        </div>
    );
}

export default Hospedagem;
