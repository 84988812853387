import React, {useEffect, useState} from 'react';
import Card from './Card';
import AdBanner from "../AdBanner";
import {getPlaces} from '../../../../api/api';
import CardSearch from './CardSearch';

export interface Local {
    id: number;
    name: string;
    description: string;
    latitude: number;
    longitude: number;
    categoryId: number;
    imageUrls: [];
    placeId: string;
}

interface CardListProps {
    categoryId: number;
}

const CardList: React.FC<CardListProps> = ({categoryId}) => {
    const [cards, setCards] = useState<Local[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadedCardsCount, setLoadedCardsCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [noResults, setNoResults] = useState(false);
    const loadedCardIds = new Set<number>();

    useEffect(() => {
        loadInitialCards();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            setCards([]);
            setLoadedCardsCount(0);
            loadedCardIds.clear();
        };
    }, [categoryId, searchTerm]);

    const loadInitialCards = async () => {
        setLoading(true);
        try {
            const initialCards = await getPlaces();
            const filteredCards = initialCards
                .filter((card: Local) => card.categoryId === categoryId)
                .filter((card: Local) => card.name.toLowerCase().includes(searchTerm.toLowerCase()));
            setCards(filteredCards);
            setNoResults(filteredCards.length === 0);
            setLoadedCardsCount(filteredCards.length);
        } catch (error) {
            console.error('Erro ao carregar os locais:', error);
        } finally {
            setLoading(false);
        }
    };

    const loadMoreCards = async () => {
        setLoading(true);

        try {
            const additionalCards = await getPlaces();
            const filteredAdditionalCards = additionalCards
                .filter((card: Local) => card.categoryId === categoryId)
                .filter((card: Local) => card.name.toLowerCase().includes(searchTerm.toLowerCase()));
            setCards(prevCards => {
                const allCards = [...prevCards, ...filteredAdditionalCards];
                const uniqueCards = Array.from(new Map(allCards.map(card => [card.id, card])).values());
                return uniqueCards;
            });
            setNoResults(filteredAdditionalCards.length === 0);
            setLoadedCardsCount(filteredAdditionalCards.length);
        } catch (error) {
            console.error('Erro ao carregar mais locais:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200
            && !loading
            && loadedCardsCount < 9
        ) {
            loadMoreCards();
        }
    };

    const handleSearch = (query: string) => {
        setSearchTerm(query);
        loadInitialCards();
    };

    return (
        <div>
            <CardSearch onSearch={handleSearch}/>
            <div className="cards-principal-lists">
                {noResults && !loading &&
                    <p className="text-danger" style={{paddingTop: '9.1rem', paddingBottom: '9.1rem'}}>Nenhum resultado
                        encontrado.</p>}
                {cards.map((local, index) => (
                    <React.Fragment key={local.id}>
                        <Card local={local}/>
                        {(index + 1) % 3 === 0 && <div className="ad-banner-container"><AdBanner/></div>}
                    </React.Fragment>
                ))}
                <div className="ad-banner-container">
                    <AdBanner/>
                </div>
                {/*{loading && (*/}
                {/*    <div>*/}
                {/*        Carregando...*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
        </div>
    );
}

export default CardList;
