import React from "react";
import HeaderApp from "../Header";
import CardList from "../Card/CardList";
import "./styles.css"
import FooterFinalPage from "../Footer/Footer";

function HospedagemApp() {
    const desiredCategoryId = 3;

    return (
        <div className='fade animate__animated animate__fadeIn'>
            <HeaderApp/>
            <CardList categoryId={desiredCategoryId}/>
            <FooterFinalPage/>
        </div>
    );
}

export default HospedagemApp;
