import React, {useEffect, useState} from "react";
import LogoCompras from '../../../../../img/logos/LogoCompras.png';
import {Link} from "react-router-dom";
import CardListChecked from "../../Card/CardListChecked";

interface Loja {
    id: number;
    nome: string;
    imagem: string;
    descricao: string;
}

const Lojas = () => {

    const desiredCategoryId = 4;
    return (
        <div className="background-container-fluid background-home-cards-fundo">
            <div className="d-flex align-items-center justify-content-center">
                <Link to="/Lojas" className="link-decoration">
                    <img src={LogoCompras} alt="Logo E Botão Que Leva Para A Página De Das Lojas" className="logos"/>
                    <h3 className="titulo ms-3 mb-0 text-black">Lojas</h3>
                </Link>
            </div>
            <div>
                <CardListChecked categoryId={desiredCategoryId}/>
            </div>
        </div>
    );
}

export default Lojas;
