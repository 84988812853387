import React, {useState} from 'react';

interface CardSearchProps {
    onSearch: (query: string) => void;
}

const CardSearch: React.FC<CardSearchProps> = ({onSearch}) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSearch(searchTerm);
    };

    const handleClear = () => {
        setSearchTerm("");
        onSearch("");
    };

    return (
        <div className="card-search-container">
            <form className="d-flex p-2 bg-blue" onSubmit={handleSearchSubmit}>
                <input
                    className="form-control me-2 flex-grow-1 bg-primary-subtle"
                    type="search"
                    placeholder="Buscar..."
                    aria-label="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <button className="btn btn-outline-primary text-white" type="submit">Buscar</button>
                <button className="btn btn-outline-danger ms-2" type="button" onClick={handleClear}>
                    Limpar
                </button>
            </form>
        </div>
    );
};

export default CardSearch;
