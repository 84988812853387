import React from "react";
import HeaderApp from "../Header";
import CardList from "../Card/CardList";
import FooterFinalPage from "../Footer/Footer";

function RestauranteApp() {
    const desiredCategoryId = 2;

    return (
        <div className='fade animate__animated animate__fadeIn'>
            <HeaderApp/>
            <div>
                <CardList categoryId={desiredCategoryId}/>
            </div>
            <div className="footerFinalPage"><FooterFinalPage/></div>
        </div>
    );
}

export default RestauranteApp;
