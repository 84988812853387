import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import HomePageIntro from "./HomePageIntro";
import PopUpScreen from "./PopUp";
import BaresRestaurantes from "./BaresRestaurantes";
import Hospedagem from "./Hospedagem";
import Lojas from "./Lojas";
import Lazer from "./Lazer";
import Importantes from "./Importantes";
import Informacoes from "./Informacoes";
import Footer from "./FooterHomePage";
import PontosTuristicos from "./PontosTuristicos";
import AdBanner from "../AdBanner";

function Home() {
    return (
        <div style={{textAlign: 'center'}}>
            <div className='fade animate__animated animate__fadeIn'>
                <HomePageIntro/>
                <PopUpScreen/>
                <AdBanner/>
                <PontosTuristicos/>
                <AdBanner/>
                <BaresRestaurantes/>
                <AdBanner/>
                <Hospedagem/>
                <AdBanner/>
                <Lojas/>
                <AdBanner/>
                <Lazer/>
                <AdBanner/>
                <Importantes/>
                <Informacoes/>
                <AdBanner/>
                <Footer/>
            </div>
        </div>
    );
}

export default Home;
