import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPlace } from '../../../../api/api';  // Função da sua API
import './QrCodeStyle.css';
import Logo from './../../../../img/logos/LogoTurismoUrbano.png'

type Place = {
    id: number;
    name: string;
    description: string;
    latitude: number;
    longitude: number;
    value: number;
    imageUrls: string[];
};

const PlaceDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [place, setPlace] = useState<Place | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchPlace = async () => {
            try {
                const data = await getPlace(Number(id)); // Chama a função da API
                setPlace(data);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar o local:', error);
                setLoading(false);
            }
        };

        fetchPlace();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!place) {
        return <div>Place not found</div>;
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/"><img src={Logo} alt="" style={{width:'200px'}}/></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/">Visite O Nosso Webiste</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className='qrcode-centralizer'>
                <div className='background-qrcode'>
                    <h1 className='qrcode-name'>{place.name}</h1>
                    <div className='qrcode-diviser'>
                        <div className='container-img-qr'>
                            <img src={place.imageUrls[0]}></img>
                        </div>
                        <div className='qrcode-container-info'>
                            <p className='qrcode-description'>{place.description}</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlaceDetails;
