import React from "react";
import './Footer.css';
const FooterFinalPage = () => {
    return (
        <div style={{position: 'relative'}}>
    <div className="Footer-Background bg-warning">
        <div className="Centralizar-De-Informações">
            <div className="Coluna-Informações">
                <div className="Informações">
                    <p className="fw-bold">Location:</p>
                    <p className="fw-semibold">Balneário Camboriú</p>
                </div>
                <div className="Informações">
                    <p className="fw-bold">E-Mail:</p>
                    <p className="fw-semibold">BC@email.com.br</p>
                </div>
            </div>
            <div className="Coluna-AboutUs">
                <div className="About-Us">
                    <p className="fw-bold">Contact Number:</p>
                    <p className="fw-semibold">(01) 2345-6789</p>
                </div>
                <div className="About-Us">
                    <p className="fw-bold">Horário De Funcionamento:</p>
                    <p className="fw-semibold">8h às 12h | 13h às 17h</p>
                </div>
            </div>
        </div>
    </div>
</div>

    );
};

export default FooterFinalPage;
