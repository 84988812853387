import React from "react";
import HeaderApp from "../Header";
import CardList from "../Card/CardList";
import FooterFinalPage from "../Footer/Footer";

function LojaApp() {
    const desiredCategoryId = 4;

    return (
        <div className='fade animate__animated animate__fadeIn'>
            <HeaderApp/>
            <CardList categoryId={desiredCategoryId}/>
            <FooterFinalPage/>
        </div>
    );
}

export default LojaApp;
