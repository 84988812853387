import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import AppRoutes from "./routes";

function App() {
    return (
        <div className='App'>
            <div className='fade animate__animated animate__fadeIn'>
                <AppRoutes/>
            </div>
        </div>
    );
}

export default App;
